(async function() {
  // GET all the degree info
  await _APP.loadDegreeData();
  // Show the degree info after we get it
  $(document).ready(() => {
    // checking to see if user is a returning student.
    $("#yesButton").on("click", function(){
      _APP.storeIsReturningStudent(true);
    });

    $("#noButton").on("click", function(){
      _APP.storeIsReturningStudent(false);
    });
   
    renderDegreeSelectOptions();
  });
})();

/**
 * Render all the possible degree selection options to choose from
 * @param {object} associateDegreeInfo - reference the ./data/AssociateDegreeInfo.json file for object structure
 * @example
 *  - <option>Degree 1</option>
 *  - <option>Degree 1</option>
 *  - <option>Degree 1</option>
 * @return HTML
 */
function renderDegreeSelectOptions(associateDegreeInfo = _APP.getAllDegrees()) {
  // Get the select element
  const $select = $("#degreeSelection");
  // Clear out anything that might already be in the list first
  $select.empty();
  $select.append("<option value='-1'>Select A Program</option>");

  // Populate the list of select options
  for (const key in associateDegreeInfo) {
    const degree = associateDegreeInfo[key];
    const $option = $("<option/>");
    $option.val(key);
    $option.text(degree.degreeTitle);
    $select.append($option);
  }
}

function onDegreeChangeHandler(event) {
  // the value of the option element
  const degreeKey = $("#degreeSelection").val();

  // If no degree is selected, do nothing.
  if (degreeKey === -1) return;

  // render the selected degree
  const selectedDegree = _APP.getDegreeByKey(degreeKey);


  // Store the selected degree into local storage
  _APP.storeMyDegree(selectedDegree);
  _APP.clearTakenCourses();
  _APP.clearMyCourses();

  const $degreeInfo = $("#degreeInfo");
  $degreeInfo.hide();
  $degreeInfo.empty().append(`
    <h1 id="degree_title">
      <h3>Total Credits:<span id="degree_credit" class="badge badge-primary">${selectedDegree.totalDegreeCredit}</span></h3>
    </h1>
    <hr>
    <p id="degree_description">${selectedDegree.degreeDetail}</p>
  `);
  $degreeInfo.show();

  // enable the button to show the `getStart` modal
  const $button = $("#submitProgramSelection");
  $button.removeClass("disabled");
}
